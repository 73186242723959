<template>
  <ContainerList ref="container" :show-page="false">
    <div slot="header">
      <div class="con_from">
        <div class="lineP">
          <div class="item">
            <i>银行流水号</i>
            <Input
              v-model="filterData.bankTrxNo"
              suffix="ios-search"
              size="large"
              maxlength="128"
              placeholder="银行流水号"
              style="width: calc(100% - 100px - 10px)"
            />
          </div>
          <div class="itembtn">
            <Button
              class="searchBt"
              @click="getDataByFilterBtn"
              style="margin-left: 20px"
              >查询</Button
            >
          </div>
        </div>
      </div>
    </div>
    <Table
      row-key
      min-width="1000px"
      class="table"
      width="100%"
      :columns="tableColumns"
      :data="tableData"
      :loading="isLoading"
    >
      <!-- <template slot="amount" slot-scope="{ row }">
        {{ (row.amount / 100) | formatMoney }}
      </template> -->
      <!-- <template slot="outStatus" slot-scope="{ row }">
        {{ outStatusData[row.outStatus] }}
      </template> -->
      <template slot="action" slot-scope="{ row }">
        <div class="actionBtn">
          <button
            v-auth="'BASIC:BALANCE:ENTRYSEAR/DETAIL'"
            class="lianjie"
            @click="toGoDetail(row)"
          >
            详情
          </button>
        </div>
      </template>
    </Table>
  </ContainerList>
</template>

<script>
import {
  getIncomeOrderList,
  getIncomeOrderListExport,
} from "@/api/basic/balance.js";
import ContainerList from "@/components/ContainerList/index";
import tableMixin from "@/mixins/tableMixin";
import { formatDate, FormatTime } from "@/utils/common.js";
import { mapState, mapMutations } from "vuex";
import Vue from "vue";

let outStatusData = {
  0: "银行处理中",
  1: "已到账",
  2: "到账失败",
};
export default {
  components: { ContainerList },
  mixins: [tableMixin],
  data() {
    let that = this;
    return {
      filterData: {
        bankTrxNo: "", // 银行流水号
      },
      totalNum: 0,
      payNum: "", //入账总数(笔)
      payAmount: "", //入账金额
      tableColumns: [
        { title: "创建时间", key: "createTime", align: "center", minWidth: 70 },
        {
          title: "商户名称",
          key: "",
          align: "center",
          minWidth: 140,
          renderHeader: (h) => {
            return h("div", [h("p", "商户名称"), h("p", "商户编号")]);
          },
          render(h, params) {
            return h(
              "div",
              {
                style: { display: "flex", flexDirection: "column" },
              },
              [
                h("p", {
                  domProps: { innerHTML: `${params.row.mchName}` },
                }),
                h("p", {
                  domProps: { innerHTML: `${params.row.mchId}` },
                }),
              ]
            );
          },
        },
        {
          title: "银行流水号",
          key: "bankTrxNo",
          align: "center",
          minWidth: 120,
        },
        {
          title: "电子账簿名称",
          key: "",
          align: "center",
          minWidth: 140,
          renderHeader: (h) => {
            return h("div", [
              h("p", "电子账簿名称"),
              h("p", "收款电子账簿编号"),
            ]);
          },
          render(h, params) {
            return h(
              "div",
              {
                style: { display: "flex", flexDirection: "column" },
              },
              [
                h("p", {
                  domProps: {
                    innerHTML: `${params.row.accountName}`,
                  },
                }),
                h("p", {
                  domProps: {
                    innerHTML: `${params.row.accountNo}`,
                  },
                }),
              ]
            );
          },
        },
        {
          title: "付款方账户名称",
          key: "",
          align: "center",
          minWidth: 140,
          renderHeader: (h) => {
            return h("div", [h("p", "付款方账户名称"), h("p", "付款方账号")]);
          },
          render(h, params) {
            return h(
              "div",
              {
                style: { display: "flex", flexDirection: "column" },
              },
              [
                h("p", {
                  domProps: {
                    innerHTML: `${params.row.payBankAccountName}`,
                  },
                }),
                h("p", {
                  domProps: {
                    innerHTML: `${params.row.payBankAccountNo}`,
                  },
                }),
              ]
            );
          },
        },
        {
          title: "付款方银行名称",
          key: "",
          align: "center",
          minWidth: 140,
          renderHeader: (h) => {
            return h("div", [h("p", "付款方银行名称"), h("p", "付款方联行号")]);
          },
          render(h, params) {
            return h(
              "div",
              {
                style: { display: "flex", flexDirection: "column" },
              },
              [
                h("p", {
                  domProps: {
                    innerHTML: `${params.row.payBankName}`,
                  },
                }),
                h("p", {
                  domProps: {
                    innerHTML: `${params.row.payBankBrnNo}`,
                  },
                }),
              ]
            );
          },
        },
        {
          title: "入账金额",
          key: "amount",
          align: "center",
          slot: "amount",
          minWidth: 70,
          renderHeader: (h) => {
            return h("div", [h("p", "入账金额(元)"), h("p", "手续费(元)")]);
          },
          render(h, params) {
            return h(
              "div",
              {
                style: { display: "flex", flexDirection: "column" },
              },
              [
                h("p", {
                  domProps: {
                    innerHTML: `${Vue.filter("formatMoney")(
                      params.row.amount / 100
                    )}`,
                  },
                }),
                h("p", {
                  domProps: {
                    innerHTML: `${Vue.filter("formatMoney")(
                      params.row.hjFee / 100
                    )}`,
                  },
                }),
              ]
            );
          },
        },
        {
          title: "入账状态",
          key: "outStatus",
          align: "center",
          slot: "outStatus",
          minWidth: 70,
          renderHeader: (h) => {
            return h("div", [h("p", "到账金额(元)"), h("p", "入账状态")]);
          },
          render(h, params) {
            return h(
              "div",
              {
                style: { display: "flex", flexDirection: "column" },
              },
              [
                h("p", {
                  domProps: {
                    innerHTML: `${Vue.filter("formatMoney")(
                      (params.row.amount - params.row.hjFee) / 100
                    )}`,
                  },
                }),
                h("p", {
                  domProps: {
                    innerHTML: `${outStatusData[params.row.outStatus]}`,
                  },
                }),
              ]
            );
          },
        },
        { title: "交易附言", key: "remark", align: "center", minWidth: 70 },
        {
          title: "操作",
          key: "action",
          align: "center",
          slot: "action",
          minWidth: 70,
        },
      ],
      tableData: [],
      outStatusOptions: [
        { label: "全部", value: -1 },
        { label: "已到账", value: "1" },
        { label: "银行处理中", value: "0" },
        { label: "到账失败", value: "2" },
      ],
      // outStatusData: {
      //   0: "银行处理中",
      //   1: "已到账",
      //   2: "到账失败",
      // },
      tableApi: getIncomeOrderList,
      tableKey: "order", // 后端返回的列表字段名
    };
  },
  computed: {
    ...mapState({
      entrySear: "trading_entrySear",
    }),
  },
  created() {
    if (this.entrySear && this.entrySear.formData) {
      this.filterData = this.entrySear.formData;
    }
    if (this.filterData.bankTrxNo !== "") {
      this.getDataByFilter();
    }
  },
  methods: {
    ...mapMutations({
      setEntrySear: "trading_setentrySear",
    }),
    // 请求前钩子
    beforeRequest(params) {
      params.bankTrxNo = params.bankTrxNo.trim();
      delete params.pageNo;
      delete params.pageSize;
      return params;
    },
    afterResponse(list, data) {
      this.totalNum = data.totalNum || 0;
      return list;
    },
    getDataByFilterBtn() {
      if (!this.filterData.bankTrxNo) {
        this.$Message.error("请输入银行流水号");
        return false;
      }
      this.getDataByFilter();
    },
    // 详情
    toGoDetail(value) {
      this.$router.push({
        name: "entryInquiry_detail",
        query: {
          id: value.id,
        },
      });
    },
  },
  beforeDestroy() {
    let data = {
      query: this.entrySear ? this.entrySear.query : null,
      formData: this.filterData,
    };
    this.setEntrySear(data);
  },
};
</script>

<style lang="less" scoped>
@import "../../../../../assets/css/desc.css";
@import "../../../../../assets/css/detail.css";
.con_from .lineP .item > i {
  width: 100px;
}
.con_from .lineP .itemTime {
  width: 49.396%;
}
.table {
  min-height: 700px;
}
.jiaoyi {
  margin-bottom: 20px;
  padding: 0px 4px;
}
</style>
