<template>
  <ContainerList
    ref="container"
    :page-size="filterData.pageSize"
    :page-current.sync="filterData.pageNo"
    :page-count="totalNum"
    @page-change="changePageNo"
    @page-size-change="changePageSize"
  >
    <div slot="header">
      <div class="con_from">
        <div class="lineP">
          <div class="item">
            <i>商户名称</i>
            <Input
              v-model="filterData.mchId"
              suffix="ios-search"
              size="large"
              maxlength="128"
              placeholder="商户名称/编号"
              style="width: calc(100% - 100px - 10px)"
            />
          </div>
          <div class="item">
            <i>电子账簿名称</i>
            <Input
              v-model="filterData.keyword"
              suffix="ios-search"
              size="large"
              maxlength="128"
              placeholder="电子账簿名称/编号"
              style="width: calc(100% - 100px - 10px)"
            />
          </div>
          <div class="item">
            <i>付款方账户名称</i>
            <Input
              v-model="filterData.payBankWord"
              suffix="ios-search"
              size="large"
              maxlength="128"
              placeholder="付款方账户名称/账号"
              style="width: calc(100% - 100px - 10px)"
            />
          </div>
        </div>
        <div class="lineP">
          <div class="item">
            <i>入账状态</i>
            <Select
              v-model="filterData.outStatus"
              size="large"
              placeholder="选择入账状态"
              style="width: calc(100% - 100px - 10px)"
            >
              <Option
                v-for="item in outStatusOptions"
                :value="item.value"
                :key="item.value"
                >{{ item.label }}</Option
              >
            </Select>
          </div>
          <div class="item itemTime">
            <i>创建时间</i>
            <DatePicker
              v-model="filterData.startTime"
              type="datetime"
              :clearable="false"
              :options="startDatePicker"
              placeholder="选择时间"
              style="
                width: calc((100% - 100px - 24.67px - 10px) / 2) !important;
              "
              format="yyyy-MM-dd HH:mm:ss"
            >
            </DatePicker>
            <span style="padding-left: 10px; padding-right: 10px">-</span>
            <DatePicker
              v-model="filterData.endTime"
              type="datetime"
              :clearable="false"
              :options="endDatePicker"
              placeholder="选择时间"
              style="
                width: calc((100% - 100px - 24.67px - 10px) / 2) !important;
              "
              format="yyyy-MM-dd HH:mm:ss"
              @on-change="endTimeChange"
            >
            </DatePicker>
          </div>
        </div>
        <div class="lineP">
          <div class="itembtn">
            <Button
              class="searchBt"
              @click="getDataByFilter"
              style="margin-left: 20px"
              >查询</Button
            >
            <button
              v-auth="'BASIC:BALANCE:ENTRYSEAR/EXPORT'"
              class="outputBt"
              @click="outputShow"
              style="margin-left: 20px"
            >
              导出
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- 中间交易账目 -->
    <div class="jiaoyi">
      <div class="jiaoyi_box">
        <p>
          <i>入账总数（笔）</i>
        </p>
        <p>
          <b>{{ payNum || 0 }}</b>
        </p>
      </div>
      <div class="jiaoyi_box">
        <p>
          <i>入账金额（元）</i>
        </p>
        <p>
          <b>{{ (payAmount / 100) | formatMoney }}</b>
        </p>
      </div>
      <div class="jiaoyi_box">
        <p>
          <i>手续费（元）</i>
        </p>
        <p>
          <b>{{ (hjFee / 100) | formatMoney }}</b>
        </p>
      </div>
      <div class="jiaoyi_box">
        <p>
          <i>到账金额（元）</i>
        </p>
        <p>
          <b>{{ (receivedAmount / 100) | formatMoney }}</b>
        </p>
      </div>
    </div>
    <Table
      row-key
      min-width="1000px"
      class="table"
      :columns="tableColumns"
      :data="tableData"
      :loading="isLoading"
    >
      <!-- <template slot="amount" slot-scope="{ row }">
        {{ (row.amount / 100) | formatMoney }}
      </template> -->
      <!-- <template slot="outStatus" slot-scope="{ row }">
        {{ outStatusData[row.outStatus] }}
      </template> -->
      <template slot="action" slot-scope="{ row }">
        <div class="actionBtn">
          <button
            v-auth="'BASIC:BALANCE:ENTRYSEAR/DETAIL'"
            class="lianjie"
            @click="toGoDetail(row)"
          >
            详情
          </button>
        </div>
      </template>
    </Table>
    <!-- 导出弹窗 -->
    <Modal
      v-model="outPayShow"
      title="导出入账记录"
      closable
      @on-cancel="outPayShow = false"
      width="490px"
    >
      <ul class="overAccount_ul">
        <li class="ul_main">
          <div class="overAccount_ul_title"><i>*</i>交易日期</div>
          <Select
            v-model="selectTimeType"
            size="large"
            placeholder="选择交易日期"
            @on-change="timeChange"
          >
            <Option
              v-for="item in selectTimeTypeOptions"
              :value="item.value"
              :key="item.value"
              >{{ item.label }}</Option
            >
          </Select>
        </li>
        <li class="ul_left">
          <div class="overAccount_ul_title" v-show="selectTimeType == -1">
            <i>*</i>开始日期
          </div>
          <DatePicker
            :options="startOption"
            v-model="outputForm.startDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择时间"
            v-show="selectTimeType == -1"
            :disabled="selectTimeType != -1"
          >
          </DatePicker>
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title" v-show="selectTimeType == -1">
            <i>*</i>结束日期
          </div>
          <DatePicker
            :options="endOption"
            v-model="outputForm.endDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择时间"
            v-show="selectTimeType == -1"
            :disabled="selectTimeType != -1"
          >
          </DatePicker>
        </li>
        <li class="ul_main" style="clear: both"></li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <Button class="exitBt" @click="outPayShow = false">取 消</Button>
        <Button class="addBt" @click="output">确 定</Button>
      </span>
    </Modal>
  </ContainerList>
</template>

<script>
import {
  getIncomeOrderList,
  getIncomeOrderListExport,
} from "@/api/basic/balance.js";
import ContainerList from "@/components/ContainerList/index";
import tableMixin from "@/mixins/tableMixin";
import { formatDate, FormatTime } from "@/utils/common.js";
import { mapState, mapMutations } from "vuex";
import Vue from "vue";

let outStatusData = {
  0: "银行处理中",
  1: "已到账",
  2: "到账失败",
};
export default {
  components: { ContainerList },
  mixins: [tableMixin],
  data() {
    let that = this;
    return {
      filterData: {
        pageNo: 1,
        pageSize: 10,
        keyword: "", // 电子记账簿名称/编号
        payBankWord: "", // 付款方账户名称/编号
        outStatus: -1, // 入账状态
        startTime: formatDate(+new Date(), "yyyy/MM/dd 00:00:00"),
        endTime: formatDate(+new Date(), "yyyy/MM/dd 23:59:59"),
      },
      formData: {
        bankTrxNo: "", // 银行流水号
      },
      totalNum: 0,
      payNum: "", //入账总数(笔)
      payAmount: "", //入账金额（元）
      hjFee: "", //手续费（元）
      receivedAmount: "", //到账金额（元）
      tableColumns: [
        {
          title: "创建时间",
          key: "createTime",
          align: "center",
          minWidth: 170,
        },
        {
          title: "商户名称",
          key: "",
          align: "center",
          minWidth: 210,
          renderHeader: (h) => {
            return h("div", [h("p", "商户名称"), h("p", "商户编号")]);
          },
          render(h, params) {
            return h(
              "div",
              {
                style: { display: "flex", flexDirection: "column" },
              },
              [
                h("p", {
                  domProps: { innerHTML: `${params.row.mchName}` },
                }),
                h("p", {
                  domProps: { innerHTML: `${params.row.mchId}` },
                }),
              ]
            );
          },
        },
        {
          title: "银行流水号",
          key: "bankTrxNo",
          align: "center",
          minWidth: 210,
        },
        {
          title: "电子账簿名称",
          key: "",
          align: "center",
          minWidth: 210,
          renderHeader: (h) => {
            return h("div", [
              h("p", "电子账簿名称"),
              h("p", "收款电子账簿编号"),
            ]);
          },
          render(h, params) {
            return h(
              "div",
              {
                style: { display: "flex", flexDirection: "column" },
              },
              [
                h("p", {
                  domProps: {
                    innerHTML: `${params.row.accountName}`,
                  },
                }),
                h("p", {
                  domProps: {
                    innerHTML: `${params.row.accountNo}`,
                  },
                }),
              ]
            );
          },
        },
        {
          title: "付款方账户名称",
          key: "",
          align: "center",
          minWidth: 210,
          renderHeader: (h) => {
            return h("div", [h("p", "付款方账户名称"), h("p", "付款方账号")]);
          },
          render(h, params) {
            return h(
              "div",
              {
                style: { display: "flex", flexDirection: "column" },
              },
              [
                h("p", {
                  domProps: {
                    innerHTML: `${params.row.payBankAccountName}`,
                  },
                }),
                h("p", {
                  domProps: {
                    innerHTML: `${params.row.payBankAccountNo}`,
                  },
                }),
              ]
            );
          },
        },
        {
          title: "付款方银行名称",
          key: "",
          align: "center",
          minWidth: 210,
          renderHeader: (h) => {
            return h("div", [h("p", "付款方银行名称"), h("p", "付款方联行号")]);
          },
          render(h, params) {
            return h(
              "div",
              {
                style: { display: "flex", flexDirection: "column" },
              },
              [
                h("p", {
                  domProps: {
                    innerHTML: `${params.row.payBankName}`,
                  },
                }),
                h("p", {
                  domProps: {
                    innerHTML: `${params.row.payBankBrnNo}`,
                  },
                }),
              ]
            );
          },
        },
        {
          title: "入账金额",
          key: "amount",
          align: "center",
          slot: "amount",
          minWidth: 100,
          renderHeader: (h) => {
            return h("div", [h("p", "入账金额(元)"), h("p", "手续费(元)")]);
          },
          render(h, params) {
            return h(
              "div",
              {
                style: { display: "flex", flexDirection: "column" },
              },
              [
                h("p", {
                  domProps: {
                    innerHTML: `${Vue.filter("formatMoney")(
                      params.row.amount / 100
                    )}`,
                  },
                }),
                h("p", {
                  domProps: {
                    innerHTML: `${Vue.filter("formatMoney")(
                      params.row.hjFee / 100
                    )}`,
                  },
                }),
              ]
            );
          },
        },
        {
          title: "入账状态",
          key: "outStatus",
          align: "center",
          slot: "outStatus",
          minWidth: 90,
          renderHeader: (h) => {
            return h("div", [h("p", "到账金额(元)"), h("p", "入账状态")]);
          },
          render(h, params) {
            return h(
              "div",
              {
                style: { display: "flex", flexDirection: "column" },
              },
              [
                h("p", {
                  domProps: {
                    innerHTML: `${Vue.filter("formatMoney")(
                      (params.row.amount - params.row.hjFee) / 100
                    )}`,
                  },
                }),
                h("p", {
                  domProps: {
                    innerHTML: `${outStatusData[params.row.outStatus]}`,
                  },
                }),
              ]
            );
          },
        },
        { title: "交易附言", key: "remark", align: "center", minWidth: 210 },
        {
          title: "操作",
          key: "action",
          align: "center",
          slot: "action",
          minWidth: 90,
        },
      ],
      tableData: [],
      outStatusOptions: [
        { label: "全部", value: -1 },
        { label: "已到账", value: "1" },
        { label: "银行处理中", value: "0" },
        { label: "到账失败", value: "2" },
      ],
      // outStatusData: {
      //   0: "银行处理中",
      //   1: "已到账",
      //   2: "到账失败",
      // },
      outPayShow: false, //导出展示
      outputForm: {}, //导出信息
      selectTimeType: "", //导出时间类型
      endOption: {
        disabledDate(date) {
          return (
            date.getTime() <
              new Date(that.outputForm.startDate) - 24 * 60 * 60 * 1000 ||
            date.getTime() >
              new Date(
                +new Date(that.outputForm.startDate) + 30 * 24 * 60 * 60 * 1000
              )
          );
        },
      },
      startOption: {
        disabledDate(date) {
          return (
            date.getTime() >
              new Date(that.outputForm.endDate) + 24 * 60 * 60 * 1000 ||
            date.getTime() <
              new Date(
                +new Date(that.outputForm.endDate) - 31 * 24 * 60 * 60 * 1000
              )
          );
        },
      },
      startDatePicker: this.startDate(),
      endDatePicker: this.endData(),
      selectTimeTypeOptions: [
        { label: "今天", value: "1" },
        { label: "昨天", value: "2" },
        { label: "近7天", value: "7" },
        { label: "其它", value: "-1" },
      ],
      tableApi: getIncomeOrderList,
      tableKey: "order", // 后端返回的列表字段名
      isFilterKey: true, // 是否开启参数转换
      emptyValue: -1,
    };
  },
  computed: {
    ...mapState({
      entrySear: "trading_entrySear",
    }),
  },
  created() {
    if (this.entrySear && this.entrySear.query) {
      this.filterData = this.entrySear.query;
      // this.filterData.outStatus = this.filterData.outStatus == '' ? '-1' : this.filterData.outStatus;
    }
    this.getDataByFilter();
  },
  methods: {
    ...mapMutations({
      setEntrySear: "trading_setentrySear",
    }),
    // 请求前钩子
    beforeRequest(params) {
      params.keyword = params.keyword.trim();
      // params.outStatus = params.outStatus == -1 ? "" : params.outStatus;
      params.startTime = FormatTime("yyyy-MM-dd hh:mm:ss", params.startTime);
      params.endTime = FormatTime("yyyy-MM-dd hh:mm:ss", params.endTime);
      return params;
    },
    afterResponse(list, data) {
      this.totalNum = data.totalNum || 0;
      this.payNum = data.payNum;
      this.payAmount = data.payAmount;
      this.hjFee = data.hjFee;
      this.receivedAmount = Number(data.payAmount) - Number(data.hjFee);
      return list;
    },
    // 详情
    toGoDetail(value) {
      this.$router.push({
        name: "entryInquiry_detail",
        query: {
          id: value.id,
        },
      });
    },
    //创建时间改变时
    endTimeChange(time, type) {
      if (type == "date" && time.slice(-8) === "00:00:00") {
        this.filterData.endTime = time.slice(0, -8) + "23:59:59";
      }
    },
    // 导出展示
    outputShow() {
      this.outPayShow = true;
      this.outputForm = {
        startDate: "",
        endDate: "",
      };
      this.selectTimeType = "";
    },
    // 选择时间类型
    timeChange(value) {
      switch (value) {
        case "1":
          this.outputForm.startDate = formatDate(+new Date(), "yyyy-MM-dd");
          this.outputForm.endDate = formatDate(+new Date(), "yyyy-MM-dd");
          break;
        case "2":
          this.outputForm.startDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.outputForm.endDate = formatDate(
            +new Date() - 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          break;
        case "7":
          this.outputForm.startDate = formatDate(
            +new Date() - 6 * 24 * 60 * 60 * 1000,
            "yyyy-MM-dd"
          );
          this.outputForm.endDate = formatDate(+new Date(), "yyyy-MM-dd");
          break;
        case "-1":
          this.outputForm.startDate = "";
          this.outputForm.endDate = "";
          break;
      }
    },
    startDate() {
      let vm = this;
      return {
        disabledDate(time) {
          return vm.filterData.endTime
            ? !(
                new Date(vm.filterData.endTime).getTime() >= time.getTime() &&
                time.getTime() >
                  new Date(vm.filterData.endTime).getTime() -
                    31 * 24 * 60 * 60 * 1000
              )
            : false;
        },
      };
    },
    endData() {
      let vm = this;
      return {
        disabledDate(time) {
          return vm.filterData.startTime
            ? !(
                new Date(vm.filterData.startTime).getTime() <= time.getTime() &&
                time.getTime() <
                  new Date(vm.filterData.startTime).getTime() +
                    31 * 24 * 60 * 60 * 1000
              )
            : true;
        },
      };
    },
    // 导出
    output() {
      if (!this.outputForm.startDate || !this.outputForm.endDate) {
        this.$Message.error("导出时间段必选");
        return false;
      }
      if (this.outputForm.startDate > this.outputForm.endDate) {
        this.$Message.error("结束时间不能小于开始时间");
        return false;
      }
      this.outputForm.startDate = formatDate(
        +new Date(this.outputForm.startDate),
        "yyyy-MM-dd"
      );
      this.outputForm.endDate = formatDate(
        +new Date(this.outputForm.endDate),
        "yyyy-MM-dd"
      );
      let data = this.outputForm;
      data.type = "2"; //1为入金导出 2为入账导出
      getIncomeOrderListExport(data).then((res) => {
        if (res) {
          let url = window.URL.createObjectURL(new Blob([res]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", "入账查询.csv");
          document.body.appendChild(link);
          link.click();
        }
        this.outPayShow = false;
      });
    },
  },
  beforeDestroy() {
    let data = {
      query: this.filterData,
      formData: this.entrySear ? this.entrySear.formData : null,
    };
    this.setEntrySear(data);
  },
};
</script>

<style lang="less" scoped>
@import "../../../../../assets/css/desc.css";
@import "../../../../../assets/css/detail.css";
.con_from .lineP .item > i {
  width: 100px;
}
.con_from .lineP .itemTime {
  width: 49.396%;
}
.table {
  min-height: 700px;
}
.jiaoyi {
  margin-bottom: 20px;
  padding: 0px 4px;
}
.addBt {
  width: 60px;
  height: 32px;
  background-color: var(--themeColor);
  border: 1px solid var(--themeColor);
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: var(--title);
  font-size: 12px;
  padding: 0;
}
.overAccount_ul {
  padding: 0px 20px;
  box-sizing: border-box;
}
.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: var(--label);
}
.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}
.ul_main {
  width: 100%;
  margin: 0 auto 7px;
}
.ul_left {
  float: left;
  width: 200px;
}
.ul-right {
  float: right;
  width: 200px;
}
</style>
