<template>
  <div id="mian">
    <Tabs v-model="crumbs">
      <TabPane label="批量查询" name="batch"></TabPane>
      <TabPane label="订单查询" name="order"></TabPane>
    </Tabs>
    <!-- 批量订单查询 -->
    <div v-if="crumbs === 'batch'">
      <BatchList></BatchList>
    </div>
    <div v-if="crumbs === 'order'">
      <OrderList></OrderList>
    </div>
  </div>
</template>
<script>
import BatchList from './components/batch_list'
import OrderList from './components/order_list'
export default {
  components: { BatchList, OrderList },
  data () {
    return {
      crumbs: 'batch'
    } 
  },
  created () {
  },
  methods: {}
};
</script>
<style scoped lang="less">
.con_from .lineP .item > i {
  width: 100px;
}
.con_from .lineP .itemTime {
  width: 49.396%;
}
.container {
  overflow: hidden;
  background: #fff;
  .table {
    min-height: 700px;
  }
  .jiaoyi {
    margin-bottom: 20px;
    padding: 0px 4px;
  }
}

</style>
